/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import "../styles/globals.css";
import { useEffect, useState } from "react";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";
import { IntercomProvider } from "react-use-intercom";
import Store from "@/store/index";
import softwareAppSchema from "@/constants/schemas/software-app";
import orgSchema from "@/constants/schemas/organization";
// import { GTM_ID, pageview as gtmPageView } from '@/lib/gtm'

// const isProduction = process.env.NODE_ENV === 'production' && /pina\.id/gi.test(process.env.NEXT_PUBLIC_BASE_URL)
// const enableAnalytics= process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === 'true'
// const enableIntercom = process.env.NEXT_PUBLIC_ENABLE_INTERCOM === 'true'
// const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => page);
  const [isRenderGTM, setIsRenderGTM] = useState(false);

  // useEffect(() => {
  //   import('react-facebook-pixel')
  //     .then((x) => x.default)
  //     .then((ReactPixel) => {
  //       ReactPixel.init('275475188528583')
  //       ReactPixel.pageView()

  //       router.events.on('routeChangeComplete', () => {
  //         ReactPixel.pageView()
  //       })
  //     })
  //   router.events.on('routeChangeComplete', gtmPageView)
  //   return () => {
  //     router.events.off('routeChangeComplete', gtmPageView)
  //   }
  // }, [router.events])

  // useEffect(() => {
  //   if (!isRenderGTM) {
  //     setTimeout(() => {
  //       setIsRenderGTM(true)
  //     }, 3500)
  //   }
  // }, [isRenderGTM])

  return getLayout(
    <Store>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name=" description"
          content="Semua Kebutuhan Trading Saham dalam Satu Platform"
        />

        <title>
          Pina Trade - Semua Kebutuhan Trading Saham dalam Satu Platform
        </title>
      </Head>

      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-6W0BXC6Q1H"
      />

      <Script id="gtm" async>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-6W0BXC6Q1H');`}
      </Script>

      <Script id="fb-pixel" async>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '275475188528583');
        fbq('track', 'PageView');`}
      </Script>

      <Script id="tag-manager" async>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MW2M6NKQ');`}
      </Script>

      <Script async id='script-segment'>
        {` !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}";;analytics.SNIPPET_VERSION="4.16.1";
        analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}");
        }}();`}
      </Script>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(orgSchema, null, 2) }}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(softwareAppSchema, null, 2),
        }}
      />

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none'}}
          src="https://www.facebook.com/tr?id=275475188528583&ev=PageView&noscript=1"
        />
      </noscript>

      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MW2M6NKQ"></iframe>
      </noscript>

      <Component {...pageProps} />
    </Store>
  );
}
